import { FaRegHandPointRight } from 'react-icons/fa'
import './index.css'
import Navigation from '../LandingPage/Navigation'


const AboutPage = () => {

    return (
        <>
            <Navigation />

            <div className='container-fluid about-page-container' style={{marginTop:'9rem'}} >
                <div className='row about-img-card'>
                    <div className='col-lg-6 mx-auto' style={{ color: 'white' }}>
                        <h1 className='about-us-heading' >E-Vyapar Exchange LLP : The Business Network Platform.</h1>
                        <p className='about-us-description'>e-Vyapar Exchange LLP is a Hyderabad referral network platform which has emerged locally and is operational across the globe. The platform network has been put in place to offer one-stop solution for enhance the business scope for members associated with the exchange platform.</p>
                        <p className='benefits-heading'>The interim benefits of the Exchange Program:</p>
                        <p><FaRegHandPointRight style={{ marginRight: 8, color: '#d9370d' }} />The members receive qualified leads all the time.</p>
                        <p><FaRegHandPointRight style={{ marginRight: 8, color: '#d9370d' }} />The referred customers would persistently increase all the time.</p>
                        <p><FaRegHandPointRight style={{ marginRight: 8, color: '#d9370d' }} />The referred customers develop a kind of loyalty for recurring sales.</p>

                    </div>
                    <div className='col-lg-4 mx-auto'>
                        <img src='http://www.vyaparexchange.com/assets/img/memberteam.png' className='management-image' />
                    </div>
                </div>
                <div className='row'>
                    <div className='card-container'>
                        <div className='our-mission-card'>
                            <img src='http://www.vyaparexchange.com/assets/img/objective.png' className='card-img' />
                            <div className='card-content'>
                                <h1 className='our-object-heading'>Our Objective</h1>
                                <p className='our-object-description'>To CONNECT business community in a relation, BUILD through technology and GROW business through EVE.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}


export default AboutPage
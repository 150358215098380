import { BsArrowRight, BsFacebook, BsPinterest, BsLinkedin } from 'react-icons/bs'
import { AiFillTwitterCircle, AiFillGooglePlusCircle } from 'react-icons/ai'
import { MdEmail, MdOutlinePhonelinkRing } from 'react-icons/md'
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from 'react-bootstrap'
import './index.css'
import { Component } from 'react'
import Navigation from '../LandingPage/Navigation';


class ContactPageData extends Component {

    state = { name: '', email: '', message: '', showNameErr: false, showEmailErr: '', showMsgErr: '', isFormSubmitted: false, isActiveBorder1: false, isActiveBorder2: false, isActiveBorder3: false, isActiveBorder4: false, isActiveBorder5: false, activeicon1: false, activeicon2: false, activeicon3: false, activeicon4: false, activeicon5: false, }


    onClickIcon1 = () => {
        this.setState({ activeicon1: true, activeicon2: false, activeicon3: false, activeicon4: false, activeicon5: false })
    }

    onClickIcon2 = () => {
        this.setState({ activeicon1: false, activeicon2: true, activeicon3: false, activeicon4: false, activeicon5: false })
    }
    onClickIcon3 = () => {
        this.setState({ activeicon1: false, activeicon2: false, activeicon3: true, activeicon4: false, activeicon5: false })
    }
    onClickIcon4 = () => {
        this.setState({ activeicon1: false, activeicon2: false, activeicon3: false, activeicon4: true, activeicon5: false })
    }
    onClickIcon5 = () => {
        this.setState({ activeicon1: false, activeicon2: false, activeicon3: false, activeicon4: false, activeicon5: true })
    }

    onChangeName = (event) => {
        this.setState({ name: event.target.value })
    }
    onChangeEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    onChangeMessage = (event) => {
        this.setState({ message: event.target.value })
    }

    validateName = () => {
        const { name } = this.state
        return name !== ""
    }

    validateEmail = () => {
        const { email } = this.state
        return email !== ""
    }

    validateMessage = () => {
        const { message } = this.state
        return message !== ''
    }

    onBlurName = () => {
        const validateName = this.validateName()
        this.setState({ showNameErr: !validateName })
    }

    onBlurEmail = () => {
        const validateEmail = this.validateEmail()
        this.setState({ showEmailErr: !validateEmail })
    }

    onBluMessage = () => {
        const validateMessage = this.validateMessage()
        this.setState({ showMsgErr: !validateMessage })
    }

    onClickInput1 = () => {
        this.setState({ isActiveBorder1: true, isActiveBorder2: false, isActiveBorder3: false, isActiveBorder4: false, isActiveBorder5: false })
    }

    onClickInput2 = () => {
        this.setState({ isActiveBorder1: false, isActiveBorder2: true, isActiveBorder3: false, isActiveBorder4: false, isActiveBorder5: false })
    }

    onClickInput3 = () => {
        this.setState({ isActiveBorder1: false, isActiveBorder2: false, isActiveBorder3: true, isActiveBorder4: false, isActiveBorder5: false })
    }

    onClickInput4 = () => {
        this.setState({ isActiveBorder1: false, isActiveBorder2: false, isActiveBorder3: false, isActiveBorder4: true, isActiveBorder5: false })
    }

    onClickInput5 = () => {
        this.setState({ isActiveBorder1: false, isActiveBorder2: false, isActiveBorder3: false, isActiveBorder4: false, isActiveBorder5: true })
    }



    onSubmitForm = (event) => {

        event.preventDefault()
        const validateName = this.validateName()
        const validateEmail = this.validateEmail()
        const validateMessage = this.validateMessage()

        if (validateName && validateEmail && validateMessage) {
            this.setState({ isFormSubmitted: true })
        } else {
            this.setState({ showNameErr: !validateName, showEmailErr: !validateEmail, showMsgErr: !validateMessage, isFormSubmitted: false })
        }
    }


    render() {

        const { name, email, message, showNameErr, showEmailErr, showMsgErr, isActiveBorder1, isActiveBorder2, isActiveBorder3, isActiveBorder4, isActiveBorder5, activeicon1, activeicon2, activeicon3, activeicon4, activeicon5 } = this.state

        return (
            <>
                <Navigation />
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='container contact-page-responsive'>
                        <div className='row contact-page-container'>
                            <div className='col-lg-6 contact-about-container'>
                                <div>
                                    <h1 className='contact-heading'>Contact With Us</h1>
                                    <div className='contact-border'></div>
                                    <p className='contact-description'>It would be great to hear from you! If you got any questions, please do not hesitate to send us a message. We are looking forward to hearing from you !We reply within 24 hours!</p>
                                </div>
                                <div className='phone-container'>
                                    <div className='logo-circle'><MdOutlinePhonelinkRing className='phone-logo' /></div>
                                    <div className='pnone-number-card'>
                                        <h1 className='contact-type'>Phone</h1>
                                        <p className='contact-value'>+919849164361</p>
                                    </div>
                                </div>
                                <div className='phone-container'>
                                    <div className='logo-circle'>   <MdEmail className='phone-logo' /></div>
                                    <div className='email-card'>
                                        <h1 className='contact-type'>Email-Id</h1>
                                        <p className='contact-value'>vyaparexchange@gmail.com</p>
                                    </div>
                                </div>
                                <div className='contact-follow-us'>
                                    <h1 className='contact-follows-heading'>Follow Us</h1>
                                    <div className='contact-follow-us-icon'>
                                        <BsFacebook onClick={this.onClickIcon1} className={activeicon1 ? `active-contact-social-icon` : `contact-social-icon`} />
                                        <AiFillTwitterCircle onClick={this.onClickIcon2} className={activeicon2 ? `active-contact-social-icon2` : `contact-social-icon2`} />
                                        <AiFillGooglePlusCircle onClick={this.onClickIcon3} className={activeicon3 ? `active-contact-social-icon2` : `contact-social-icon2`} />
                                        <BsPinterest onClick={this.onClickIcon4} className={activeicon4 ? `active-contact-social-icon` : `contact-social-icon`} />
                                        <BsLinkedin onClick={this.onClickIcon5} className={activeicon5 ? `active-contact-social-icon` : `contact-social-icon`} style={{ borderRadius: 13 }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 contact-form-bg-container'>
                                <h1 className='leave-msg-heading'>Leave A Message</h1>
                                <div className='contact-border'></div>
                                <form className='contact-form-container' onSubmit={this.onSubmitForm}>

                                    <div className='mb-2'><input type='text' id='name' value={name} placeholder='Enter name' className={isActiveBorder1 ? `active-contact-input-box` : `contact-input-box`} onChange={this.onChangeName} onBlur={this.onBlurName} onClick={this.onClickInput1} />
                                        {showNameErr && <p style={{ color: 'red', marginBottom: 0, marginTop: 0 }} className='required'>Required*</p>}
                                    </div>
                                    <div className='mb-2'>
                                        <input type='text' id='email' value={email} placeholder='Enter email' className={isActiveBorder2 ? `active-contact-input-box` : `contact-input-box`} onChange={this.onChangeEmail} onBlur={this.onBlurEmail} onClick={this.onClickInput2} />
                                        {showEmailErr && <p style={{ color: 'red', marginBottom: 0, marginTop: 0 }} className='required'>Required*</p>}
                                    </div>
                                    <div className='mb-2'>
                                        <input type='text' id='phonenumber' placeholder='Enter Mobile number' className={isActiveBorder3 ? `active-contact-input-box` : `contact-input-box`} onClick={this.onClickInput3} />
                                    </div>
                                    <div className='mb-2'>
                                        <input type='text' id='subject' placeholder='Enter Subject' className={isActiveBorder4 ? `active-contact-input-box` : `contact-input-box`} onClick={this.onClickInput4} />
                                    </div>
                                    <div className='mb-2'>
                                        <textarea type='text' id='message' value={message} placeholder='Enter message' className={isActiveBorder5 ? `active-contact-input-box` : `contact-input-box1`} onChange={this.onChangeMessage} onBlur={this.onBluMessage} onClick={this.onClickInput5}></textarea>
                                        {showMsgErr && <p style={{ color: 'red', marginBottom: 0, marginTop: 0 }} className='required'>Required*</p>}
                                    </div>

                                    <div className='text-center mt-3 g-recaptcha'>
                                        <ReCAPTCHA
                                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                            onChange={this.onChange}
                                        />

                                    </div>
                                    <div className='text-start'><Button type='submit' variant="outline-danger" className='contact-submit-btn'>Submit <BsArrowRight style={{ marginLeft: 8 }} /> </Button></div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className='map-container'>
                        <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="510" id="gmap_canvas" src="https://maps.google.com/maps?q=hyderabad&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br /></div></div>
                    </div>
                </div>
            </>
        )
    }
}


export default ContactPageData
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import './Style.css'
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaRupeeSign } from 'react-icons/fa';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SearchItem from '../Dashboard/SearchItem';




const Navigation = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate()

    const [products, setProductList] = useState([])

    const searchProduct = (product) => {
        if (product === '') {
            setProductList([])
            return
        }
        axios.post('https://vyaparapi.emedha.in/search-product', {
            product
        }).then((res) => {
            setProductList(res.data)
        })
    }

    const [searchData, setSearchData] = useState([])
    const [open2, setOpen2] = useState(false)

    const showSearchProduct = (id) => {
        const searchItem = products.filter((data) => {
            if (data.id === id) {
                return data
            }
        })
        setSearchData(searchItem)
    }


    return (
        <div className='card nav-container' style={{ width: '100%', height: '7rem', background: 'red', borderRadius: '0.0003rem', border: 'none', justifyContent: 'center', position: 'fixed', top: '0px', zIndex: '10' }} >
            <div className='container-fluid card d-none d-lg-flex' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

                <div style={{ width: '22rem', paddingLeft: 7 }} className='v-con-logo-con d-none d-lg-block'>
                    <div style={{ position: 'relative', top: '-0.3rem' }} >
                        <Typography className='v-con-logo' style={{ color: 'white', fontSize: '1.4rem', fontWeight: '800', }} ><img src='https://th.bing.com/th/id/OIP.yexM5GOQwF5P104LDy9G8wHaHa?w=177&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' style={{ width: '27px', borderRadius: '0.1rem', marginRight: 3, position: 'relative', top: '0.2rem' }} />E-VyaparExchange<span className='v-store-t' style={{ marginTop: 3, fontSize: '1.1rem' }}>LLP</span></Typography>
                        <div style={{ width: '7.3rem', height: '0.19rem', backgroundColor: 'white', position: 'relative', top: '-0.2rem', left: 34, borderRadius: '0.4rem', }} ></div>
                    </div>
                </div>

                <div className='d-flex' style={{ width: '18rem', border: 'none', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', backgroundColor: 'red' }}>
                    <Typography onClick={() => navigate('/About')} className='menu-link' style={{ color: 'white' }}>About</Typography>
                    <a onClick={handleClickOpen} style={{ textDecoration: 'none', color: 'white' }} ><Typography className='menu-link' style={{ color: 'white' }}>Download App</Typography></a>
                    <Typography onClick={() => navigate('/Contact')} className='menu-link' style={{ color: 'white' }}>Contact</Typography>
                </div>

                <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '1.2rem', paddingLeft: 8, paddingRight: 8, display: 'flex', alignItems: 'center' }} >
                    <SearchRoundedIcon />
                    <input onClick={handleClickOpen1} readOnly className='ps-3' style={{ background: 'white', border: 'none', height: '2.6rem', width: '27rem', borderRadius: '1.2rem', outline: 'none' }} placeholder='Search Vyapar Products' ></input>
                    <ListRoundedIcon style={{ marginTop: -2 }} />
                </div>

                <div className='sign-in-btn p-2' onClick={() => navigate('/login')}  >
                    <Typography style={{ border: 'none', color: 'white', fontWeight: '700' }} ><PersonAddAltRoundedIcon style={{ position: 'relative', top: '-0.1rem' }} /> Sign In</Typography>
                </div>
            </div>

            <div className='container-fluid card d-flex d-lg-none' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
                <div style={{ position: 'relative', borderRadius: '0.2rem', width: '100%', paddingBottom: 10, justifyContent: 'space-between', display: 'flex' }} >
                    <div style={{ width: '17rem', }} className='v-con-logo-con d-flex'>
                        <div className='d-flex' style={{ position: 'relative', top: '-0.3rem' }} >
                            {/* <MenuOutlinedIcon style={{ fontSize: 22, color: 'white', position: 'relative', top: 5.2, marginRight: 10 }} /> */}
                            <Typography style={{ color: 'white', fontSize: '1.2rem', fontWeight: '600', }} >E-VyaparExchange<span className='v-store-t' style={{ marginTop: 3, fontSize: '1rem' }}>LLP</span></Typography>
                        </div>
                    </div>
                    <Typography onClick={() => navigate('/login')} style={{ border: 'none', color: 'white', fontWeight: '600', fontSize: 14 }} ><PersonAddAltRoundedIcon style={{ position: 'relative', top: '-0.1rem' }} /> Login</Typography>
                </div>
            </div>

            <div className='container-fluid card d-flex d-lg-none' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
                <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.2rem', paddingLeft: 8, paddingRight: 8, width: '100%', display: 'flex', alignItems: 'center' }} >
                    <SearchRoundedIcon style={{ fontSize: 25, color: 'gray', marginTop: -2 }} />
                    <input onClick={handleClickOpen1} readOnly className='ps-1' style={{ background: 'white', border: 'none', height: '2.6rem', width: '16rem', borderRadius: '0.2rem', outline: 'none' }} placeholder='Search Vyapar Products' ></input>
                </div>
            </div>

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogContent>
                        <div style={{ backgroundColor: 'rgb(255, 87, 87)', width: '100%', padding: 10 }} >
                            <div style={{ marginBottom: 17 }} >
                                <Typography style={{ fontSize: '2rem', color: 'white', fontWeight: 'bold' }} >Get Start With Vyapar Connect App</Typography>
                                <Typography style={{ color: 'white' }} > Download Vyapar connect app</Typography>
                            </div>
                            <a target='__blank' href="https://play.google.com/store/apps/details?id=com.emedhatec.vyaparconnect" ><img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/fqemcqvzvzexrrlijuo7.png" /></a>
                            <img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/c1fds2uovbd7q3udrpc5.png" />

                        </div>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth='lg'
                >
                    <DialogContent>
                        <div style={{ padding: 4, width: '20rem', height: '31rem' }} >
                            <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', marginBottom: 16, dispaly: 'flex', alignItems: 'center' }} className='d-flex align-items-center'>

                                <input onChange={(e) => searchProduct(e.target.value)} onClick={handleClickOpen1} className='ps-1 d-flex align-items-center' style={{ background: 'white', border: 'none', height: '2.6rem', width: '100%', outline: 'none', borderBottom: '2px solid gray' }} placeholder='Search Vyapar Products' ></input>
                                <SearchRoundedIcon style={{ color: 'black', position: 'absolute', right: '3px', top: '9px' }} />
                            </div>
                            {
                                products.length !== 0 && (
                                    <div style={{ justifyContent: 'center', alignItems: 'center' }}  >
                                        <Typography style={{ color: 'gray', marginBottom: 14 }} >Click to more details</Typography>
                                        {
                                            products.slice(0, 8).map((data) => {
                                                return (
                                                    <Box onClick={() => {
                                                        setOpen2(true)
                                                        setOpen1(false)
                                                        showSearchProduct(data.id)
                                                    }} className='search-prpduct' sx={{ display: 'flex', marginBottom: 2, borderBottom: '1px solid lightgray', padding: 1 }} >
                                                        <div style={{ width: 89, overflow: 'hidden', marginLeft: 7 }} >
                                                            <img style={{ height: 43 }} className="img" src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} alt="1004" />
                                                        </div>
                                                        <Typography>
                                                            <Typography>{data.pName}</Typography>
                                                            <Typography style={{ fontSize: 12, fontWeight: 'bold' }} ><FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />{data.pPrice - data.discount / 100 * data.pPrice} /-</Typography>
                                                        </Typography>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            {
                                products.length === 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >


                                        <Box>
                                            <Typography>Search Your Products</Typography>
                                        </Box>

                                    </div>
                                )
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleClose1} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                {
                    open2 && (
                        <SearchItem searchData={searchData} open2={open2} setOpen2={setOpen2} />
                    )
                }
            </div>

        </div>
    )
}

export default Navigation
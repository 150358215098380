import { Button } from 'react-bootstrap'
import './index.css'
import { useAppStore } from '../AppStore'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CgDetailsMore } from 'react-icons/cg'
import StoreProductDetails from '../StoreProductDetailView'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddProductForm from '../AddProductForm'
import { Box, CardActionArea } from '@mui/material'
import { FaRupeeSign } from 'react-icons/fa'
import { styled } from '@mui/material/styles';
import TopCarausel from '../LandingPage/TopCarausel'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));

const MyStore = () => {

    const openAddProductForm = () => {

        setIsAddProduct(true)
    }

    const updateState = useAppStore((state) => state.updateOpen)

    const dopen = useAppStore((state) => state.dopen)

    const [isOpenProductDeailView, setDetailProductView] = useState(false)

    const [allProducts, setAllProduct] = useState([])

    const getAllProduct = () => {
        const email = sessionStorage.getItem('email')
        axios.post("https://vyaparapi.emedha.in/my-product/get", {
            email: sessionStorage.getItem('email')
        }).then((res) => {
            setAllProduct(res.data)

        })
    }

    useEffect(() => {
        getAllProduct()
    }, [])



    const getProductData = () => {
        axios.get("https://vyaparapi.emedha.in/product/get").then((resp) => {
            //set product
            console.log(resp.data)
        })
    }


    const [dis, setDis] = useState()

    useEffect(() => {
        getProductData()

        setDis(dis)
    }, [])

    const disableDate = () => {
        var today, dd, mm, yyyy;
        today = new Date();
        dd = today.getDate() + 1;
        mm = today.getMonth() + 1
        yyyy = today.getFullYear()
        return yyyy + "-" + mm + "-" + dd;
    }


    const [isAddProduct, setIsAddProduct] = useState(false)


    return (
        <>
            {
                isAddProduct && (
                    <AddProductForm></AddProductForm>
                )
            }
            {
                !isAddProduct && (
                    <Box sx={{ flexGrow: 1 }}>
                        <div style={{ marginTop: 126 }} >
                            <TopCarausel></TopCarausel>
                        </div>
                        <button onClick={() => setIsAddProduct(true)} style={{ borderRadius: '0.2rem', fontSize: 15, border: 'none', backgroundColor: 'green', color: 'white', height: '2.2rem', width: '7.5rem', marginTop: 17, marginLeft: 9 }}>Add Product</button>
                        <Grid container sx={{ paddingLeft: 1, paddingRight: 1, marginTop: 2, marginBottom: 5, paddingBottom: 4 }} spacing={2}>
                            {
                                allProducts.map((data) => {
                                    return (
                                        <Grid item lg={2} xs={6}>
                                            <Item elevation={1} style={{ height: 340, position: 'relative', borderRadius: '0.6rem' }} className='item'>
                                                <Card elevation={0} sx={{ maxWidth: '100%', justifyContent: 'center', alignItems: 'center', borderRadius: '0.6rem' }}>
                                                    <center>
                                                        <center>
                                                            <CardActionArea>
                                                                <center>
                                                                    <div class='cards1' style={{ overflow: 'hidden', marginBottom: 4 }} >
                                                                        <div style={{ width: '96%', overflow: 'hidden' }} >
                                                                            <center> <img style={{ height: '8rem' }} src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} alt="1004" /></center>
                                                                        </div>
                                                                    </div>
                                                                </center>
                                                            </CardActionArea>
                                                        </center>
                                                    </center>
                                                </Card>
                                                <CardContent className='d-none d-md-block' >
                                                    <Box style={{ height: '10rem', position: 'absolute', left: 0, right: 0, bottom: 0, padding: 10 }}>
                                                        <Typography style={{ fontSize: '1.3rem', color: 'black' }} >{data.pName}</Typography>
                                                        <Typography style={{ fontSize: 15 }}> <Typography style={{ color: 'black' }} ><FaRupeeSign style={{ fontSize: 14, position: 'relative', top: '-0.1rem' }} />{data.pPrice - data.discount / 100 * data.pPrice} <span className='product-detail-orginal-cost' style={{ fontSize: 14, color: 'gray' }}>{data.pPrice}</span><span style={{ fontSize: 15, fontWeight: '700', color: 'green', fontStyle: 'italic' }}>{data.discount}% Off</span>  </Typography></Typography>

                                                        <Typography style={{ fontSize: 12 }}><span style={{ fontSize: 12 }}>Validity: </span>{data.validity}</Typography>

                                                        <div className='d-flex' style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
                                                            <button style={{ borderRadius: '0.2rem', fontSize: 14, border: 'none', backgroundColor: 'green', color: 'white', height: '1.8rem', width: '5.5rem', }}>Edit</button>
                                                            <button style={{ borderRadius: '0.2rem', fontSize: 14, border: 'none', backgroundColor: 'red', color: 'white', height: '1.8rem', width: '5.5rem', }}>Delete</button>
                                                        </div>
                                                    </Box>
                                                </CardContent>
                                                <CardContent className='d-block d-md-none' >
                                                    <Box style={{ height: '10rem', position: 'absolute', left: 0, right: 0, bottom: 0, padding: 10 }}>
                                                        <Typography style={{ fontSize: '1.3rem', color: 'black' }} >{data.pName}</Typography>
                                                        <Typography style={{ fontSize: 12, marginTop: 6 }}> <Typography style={{ color: 'black' }} ><FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />{data.pPrice - data.discount / 100 * data.pPrice} <span className='product-detail-orginal-cost' style={{ fontSize: 12, color: 'gray' }}>{data.pPrice}</span><span style={{ fontSize: 12, fontWeight: '700', color: 'green', fontStyle: 'italic' }}>{data.discount}% Off</span>  </Typography></Typography>

                                                        <Typography style={{ fontSize: 12 }}><span style={{ fontSize: 12 }}>Validity: </span>{data.validity}</Typography>

                                                        <div className='d-flex' style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
                                                            <button style={{ borderRadius: '0.2rem', fontSize: 14, border: 'none', backgroundColor: 'green', color: 'white', height: '1.8rem', width: '4.6rem', }}>Edit</button>
                                                            <button style={{ borderRadius: '0.2rem', fontSize: 14, border: 'none', backgroundColor: 'red', color: 'white', height: '1.8rem', width: '4.6rem', }}>Delete</button>
                                                        </div>
                                                    </Box>
                                                </CardContent>
                                            </Item>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                )
            }
        </>
    )
}

export default MyStore
import { Component } from 'react'

import { Container, Card, Row, Col, Form, Modal, Nav, Button } from 'react-bootstrap'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import './index.css'
import TextField from '@mui/material/TextField';
import RegisterForm from '../RegisterForm'
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaCaretLeft } from 'react-icons/fa';

class LoginPage extends Component {

    state = { isOpen: false, guestEmail: "", lgShow: false, setLgShow: false, isActiveBtn: false, username: '', loginEmail: '', loginPass: '', showPasswordErr: false, showEmailErr: false, isFormSubmit: false }

    onChangeUsername = event => {
        this.setState({ username: event.target.value })
    }

    onChangeEmail = event => {
        this.setState({ loginEmail: event.target.value })
    }

    onChangePassword = event => {
        this.setState({ loginPass: event.target.value })
    }

    validEmail = () => {
        const { loginEmail } = this.state
        return loginEmail !== ""
    }

    validPassword = () => {
        const { loginPass } = this.state
        return loginPass !== ""
    }

    onBlurPassword = () => {
        const validPassword = this.validPassword()
        this.setState({ showPasswordErr: !validPassword })
    }

    onBlurEmail = () => {
        const validEmail = this.validEmail()
        this.setState({ showEmailErr: !validEmail })
    }

    onChange = () => {
        this.setState({ isActiveBtn: true })
    }

    onChangeGuestEmail = event => {
        this.setState({ guestEmail: event.target.value })


    }

    onSubmitForm = (event) => {
        event.preventDefault()
        const validEmail = this.validEmail()
        const validPassword = this.validPassword()

        const email = {
            loginEmail: this.state.loginEmail
        };

        const password = {
            loginPass: this.state.loginPass
        }

        const loginPass = password.loginPass
        const loginEmail = email.loginEmail

        axios.post(`https://vyaparapi.emedha.in/login`, { loginEmail, loginPass })

            .then(res => {
                if (res.data.statusCode === 200) {

                    alert(res.data.message)

                    window.location.href = '/user-dashboard';

                    sessionStorage.setItem('key', loginEmail)
                    sessionStorage.setItem(`email`, loginEmail)
                    sessionStorage.setItem('name', res.data.name)
                    sessionStorage.setItem('company', res.data.company)
                    sessionStorage.setItem('mobile', res.data.mobile)
                    sessionStorage.setItem('location', res.data.location)
                    sessionStorage.setItem('userId', res.data.userId)
                    sessionStorage.setItem('classification', res.data.subVertical)
                } else {
                    alert(res.data)
                }
            })


        if (validEmail && validPassword) {
            this.setState({ isFormSubmit: true })
            sessionStorage.setItem("authenticated", 'login');
        } else {
            this.setState({
                showEmailErr: !validEmail,
                showPasswordErr: !validPassword,
                isFormSubmit: false

            })

        }

    }

    openEmailPop = () => {
        this.setState({ setLgShow: true, lgShow: true })
    }

    closePop = () => {
        this.setState({ setLgShow: false, lgShow: false })
    }

    onOpenRegisterPage = () => {
        this.setState({ isOpen: true })
    }



    render() {
        const { isOpen, lgShow, setLgShow, guestEmail, isActiveBtn, username, loginEmail, loginPass, showPasswordErr, showEmailErr, isFormSubmit } = this.state

        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }} >
                <>
                    <div className='col-lg-6 col-md-7 col-12 mx-auto mb-3'>
                        <div style={{ display: 'flex', alignItems: 'center', padding: 3, backgroundColor: 'white' }} className='shadow col-12'>
                            <Typography style={{ color: 'black', cursor: 'pointer' }} onClick={() => window.location.href = '/'} ><FaCaretLeft style={{ marginTop: -4 }} /> Home <img src={require('../Images/vyaparlogo.png')} style={{ width: 50 }} /></Typography>
                        </div>
                        <br />
                        <div className='container w-100 w-lg-50 mt-1 mt-md-5' >

                            <div className='row' style={{ backgroundColor: 'lightgray', padding: 5, borderRadius: 3 }}>
                                <div className='col-lg-5 mx-auto' style={{ position: 'relative' }}>
                                    <div style={{ marginTop: 24 }} >
                                        <Typography style={{ fontSize: 28, fontWeight: 'bold', color: 'red' }} >Login</Typography>
                                        <Typography style={{ width: '70%', fontSize: 13 }} >Get acees to contact saller with the help of email and whatsApp referral</Typography>
                                    </div>
                                    <img className='d-flex d-md-none' style={{ width: '100%', borderRadius: 3 }} src={require('./image/login.jpeg')} />
                                    <img className='d-none d-md-flex' style={{ position: 'absolute', bottom: 16, left: 0, width: '100%', borderRadius: 3 }} src={require('./image/login.jpeg')} />
                                </div>
                                <div className='col-lg-7 mx-auto' style={{ borderRadius: 5, border: '0.8px solid lightgray' }}>
                                    <div className='mt-2'>
                                        <TextField onChange={this.onChangeEmail} label='Email *' style={{ width: '100%' }} variant='standard' placeholder='Enter login email' />
                                        <br />
                                        <br />
                                        <TextField onChange={this.onChangePassword} label='Password *' type='password' style={{ width: '100%' }} variant='standard' placeholder='Enter login password' />
                                        {showPasswordErr && <p style={{ color: 'red', marginBottom: 0 }}>Required*</p>}

                                        <br />
                                        <br />
                                        <br />
                                        <div className='text-center mt-3 mb-2 g-recaptcha'>

                                            <ReCAPTCHA
                                                sitekey="6LcKzpknAAAAAM3IGeFPi75Qh1HZDkuY9X7wDLfo"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <button onClick={this.onSubmitForm} className='pss-btn' style={{ backgroundColor: 'red', borderRadius: 0.2, width: '100%', border: 'none', height: '2.5rem', color: 'white' }}><Typography style={{ fontWeight: '600' }} >Login</Typography></button>
                                        <br />
                                        <br />
                                        <button className='pss-btn' style={{ backgroundColor: 'white', borderRadius: 0.2, width: '100%', border: 'none', height: '2.5rem', color: 'red' }}><Typography style={{ fontWeight: '500' }} >Forgot Password ?</Typography></button>

                                        <div className='d-flex flex-column' style={{ marginTop: '5rem', marginBottom: '5rem' }} >
                                            <Typography onClick={() => window.location.href= '/sign-up'} style={{ cursor: 'pointer', color: 'red', textAlign: 'center', fontSize: 13 }} >New to vyapar connect? Create account</Typography>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        size="md"
                        show={lgShow}
                        onHide={this.closePop}
                        aria-labelledby="example-modal-sizes-title-lg"
                        centered
                        backgroundColor='#e3010f'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                Guest Login
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>

                                <div className='row email-form-container'>
                                    <div className='col-lg-12 mx-auto m-2 border-0 ' style={{ border: '1px solid black', borderRadius: 5 }}>
                                        <Card className='m-2 otp-card'>
                                            <Card.Header style={{ backgroundColor: 'rgb(251, 253, 255)' }} >
                                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                                    <h1 style={{ fontSize: 18, padding: 10, color: 'black' }}>Email  Verification</h1>
                                                    <img src='https://play-lh.googleusercontent.com/NZaFTTPY51Zv7SvfhssvX26hlNfJB1O8HLjdkPg1a1Zepgi5ORFEaN1ZohgFbB1-lp41' style={{ width: 60 }} alt='logo' />

                                                </div>
                                            </Card.Header>

                                            <Form className='p-3' style={{ backgroundColor: 'lightcyan' }}>
                                                <Form.Label style={{ marginTop: 10, marginLeft: 2, color: 'black', fontWeight: '600' }}>Enter Your Email</Form.Label>
                                                <Form.Control type='email' id='email' className='input-box' onChange={this.onChangeGuestEmail} value={guestEmail} />

                                                <button className='verify-otp-button' style={{ backgroundColor: 'rgb(13, 10, 70)', marginTop: 20, borderRadius: 5 }}>Submit</button>

                                            </Form>
                                        </Card>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                </>
            </div>
        )
    }
}

export default LoginPage



